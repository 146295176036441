/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Location } from '@reach/router';
// import { useStaticQuery, graphql } from 'gatsby';
import styled, { ThemeProvider } from 'styled-components';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

import HeaderComponent from './HeaderComponent';
import FooterComponent from './FooterComponent';
import './layout.css';

library.add(fab, fas, far);

const theme = {
  primeBluePalette: {
    light: '#003366',
    original: '#003366',
    dark: '#003366',
  },
  primeYellowPalette: {
    light: '#FAD127',
    original: '#FAD127',
    dark: '#FAD127',
  },
  primeRedPalette: {
    light: '#FF0000',
    original: '#FF0000',
    dark: '#FF0000',
  },
  blackPalette: {
    light: '#5C5C5C',
    original: '#202020',
    dark: '#0F0F0F',
  },
  bluePalette: {
    light: '#1BA6FF',
    original: '#0080FF',
    dark: '#0073E6',
  },
  yellowPalette: {
    light: '#FFCD00',
    original: '#FBC732',
    dark: '#E0B12D',
  },
  redPalette: {
    light: '#E74217',
    original: '#C03614',
    dark: '#A62E11',
  },
  greenPalette: {
    light: '#35CD71',
    original: '#2DAF60',
    dark: '#279654',
  },
  whitePalette: {
    light: '#E6E6E6',
    original: '#FFFFFF',
    dark: '#BFBFBF',
  },
  coldBluePalette: {
    light: '#F2F2F5',
    original: '#F2F2F9',
  },
};

const pageLinks = [
  {
    url: 'https://primephilippines.com/prime-advantage',
    name: 'About Us',
    external: true,
  },
  {
    url: 'https://primephilippines.com/our-expertise/experienced',
    name: 'Our Expertise',
    external: true,
  },
  {
    url: 'https://www.primephilippines.com/press-room',
    name: 'Press Room',
    external: true,
  },
];

const AppContainer = styled.div`
  color: ${`${theme.blackPalette.original}E6`};

  * {
    font-family: 'Proxima Nova';
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Proxima Nova';
    margin-bottom: 14px;
    font-weight: 700;
  }

  p {
    margin-bottom: 14px;
    line-height: calc(1rem + 4px);
  }
`;

const Layout = ({ children, headerstyle }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  return (
    <AppContainer>
      <ThemeProvider theme={theme}>
        <div
          style={{
            margin: `0 auto`,
            overflow: `hidden`,
          }}
        >
          <Location>
            {({ location }) => (
              <HeaderComponent
                location={location}
                pageLinks={pageLinks}
                headerstyle={headerstyle}
              />
            )}
          </Location>

          <main>{children}</main>

          <FooterComponent />
        </div>
      </ThemeProvider>
    </AppContainer>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headerstyle: PropTypes.string,
};

export default Layout;
