import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Col, Drawer } from 'antd';

import logo from '../../static/logo.png';

const StyledHeader = styled.div`
  width: 100%;
  position: fixed;
  z-index: 10;
  transition: 0.4s;
  background-color: ${(props) =>
    (props.scrolling > 50 && !props.drawerVisibility) ||
    (props.headerstyle === 'default' && !props.drawerVisibility)
      ? props.theme.whitePalette.original
      : 'transparent'};
  box-shadow: ${(props) =>
    (props.scrolling > 50 && !props.drawerVisibility) ||
    (props.headerstyle === 'default' && !props.drawerVisibility)
      ? '0px 1px 10px rgba(0, 0, 0, 0.1)'
      : 'none'};

  .links {
    height: 100%;

    .link-wrapper {
      padding: 0px 14px;
    }
  }

  .active {
    color: ${(props) =>
      props.scrolling > 50
        ? props.theme.yellowPalette.dark
        : props.theme.yellowPalette.light};
  }
`;

const StyledLogo = styled.div`
  img {
    cursor: pointer;
    padding: 10px 0px 10px 14px;
    height: 64px;
    background-color: white;
    float: left;
    margin-bottom: 0;
  }

  &:after {
    content: '';
    float: left;
    border: transparent;
    border-left: 32px solid white;
    border-right: 32px solid transparent;
    border-bottom: 32px solid transparent;
    border-top: 32px solid white;
  }
`;

const StyledLink = styled.a`
  letter-spacing: 1.5px;
  font-weight: 400;
  font-size: 16px;
  transition-delay: 0.1s;
  color: ${(props) =>
    props.scrolling > 50 || props.headerstyle === 'default'
      ? props.theme.blackPalette.original
      : props.theme.whitePalette.light};

  .active {
    color: ${(props) =>
      props.scrolling > 50 || props.headerstyle === 'default'
        ? props.theme.bluePalette.dark
        : props.theme.bluePalette.light};
  }

  :hover {
    color: ${(props) =>
      props.scrolling > 50 || props.headerstyle === 'default'
        ? props.theme.bluePalette.dark
        : props.theme.bluePalette.light};
  }
`;

const StyledHamburger = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  padding-right: 8px;

  .default-button {
    padding: 14px;
    transition: 0.4s;

    .styled-line {
      width: 32px;
      height: 4px;
      background-color: ${(props) =>
        props.scrolling > 50 ||
        props.drawerVisibility ||
        props.headerstyle === 'default'
          ? props.theme.blackPalette.original
          : props.theme.whitePalette.original};
      border-radius: 5px;
      margin: ${(props) => (props.drawerVisibility ? '' : '4px 0px')};
      transition-delay: 0.4s;
      transition: 0.4s;
    }

    .styled-line:nth-child(1) {
      transform: ${(props) =>
        props.drawerVisibility ? 'Rotate(225deg) translate(-2px,-4px)' : ''};
    }
    .styled-line:nth-child(2) {
      transform: ${(props) => (props.drawerVisibility ? 'Rotate(45deg)' : '')};
      opacity: ${(props) => (props.drawerVisibility ? 0 : 1)};
    }
    .styled-line:nth-child(3) {
      transform: ${(props) =>
        props.drawerVisibility ? 'Rotate(315deg) translate(2px,-4px)' : ''};
    }
  }

  .hidden {
    transform: ${(props) => (props.drawerVisibility ? 'Rotate(180deg)' : '')};
  }
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-content {
    background-color: #ffffffe6;

    .ant-drawer-body {
      height: 100%;
      padding: 64px 14px 0px;
    }
  }

  @media screen and (min-width: 992px) {
    display: none;
  }
`;

const StyledDrawerContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  a {
    display: flex;
    justify-content: center;
    font-size: 18px;
    margin: 8px 0px;
  }

  .active {
    color: ${(props) =>
      props.scrolling > 50
        ? props.theme.yellowPalette.dark
        : props.theme.yellowPalette.light};
  }
`;

const defaultLinks = [{ url: '/', name: 'Home' }];

const LinksComponent = ({ pages, location, scrollTop, headerstyle }) => (
  <Row type="flex" justify="end" align="middle" className="links">
    {pages.map((value) => (
      <Col className="link-wrapper" key={value.url}>
        <StyledLink
          href={value.url}
          scrolling={scrollTop}
          headerstyle={headerstyle}
          className={value.url === location.pathname ? 'active' : ''}
        >
          {value.name}
        </StyledLink>
      </Col>
    ))}
  </Row>
);

const HamburgerComponent = ({
  scrollTop,
  drawerVisibility,
  handleOnClick,
  headerstyle,
}) => (
  <StyledHamburger
    scrolling={scrollTop}
    drawerVisibility={drawerVisibility}
    onClick={() => handleOnClick()}
    headerstyle={headerstyle}
  >
    <div className="default-button hidden">
      <div className="styled-line" />
      <div className="styled-line" />
      <div className="styled-line" />
    </div>
  </StyledHamburger>
);

const Content = ({ pages, location }) => (
  <StyledDrawerContent>
    <StyledLink
      to="/"
      className={location.pathname === '/' ? 'active' : ''}
      scrolling={100}
    >
      Home
    </StyledLink>
    {pages.map((value) => (
      <StyledLink
        to={value.url}
        className={value.url === location.pathname ? 'active' : ''}
        scrolling={100}
        key={value.url}
      >
        {value.name}
      </StyledLink>
    ))}
  </StyledDrawerContent>
);

const HeaderComponent = ({ pageLinks, location, headerstyle }) => {
  const [scrollTop, setScrollTop] = useState(0);
  const [drawerVisibility, setDrawerVisibility] = useState(false);

  const pages = pageLinks
    ? pageLinks.length > 0
      ? pageLinks
      : defaultLinks
    : defaultLinks;

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  const handleOnClick = () => {
    setDrawerVisibility(!drawerVisibility);
  };

  return headerstyle !== 'none' ? (
    <StyledHeader
      scrolling={scrollTop}
      drawerVisibility={drawerVisibility}
      headerstyle={headerstyle}
    >
      <Row type="flex">
        <Col lg={8} sm={18} xs={18}>
          <Link to="/">
            <StyledLogo>
              <img src={logo} alt="PRIME logo" />
            </StyledLogo>
          </Link>
        </Col>
        <Col lg={16} sm={0} xs={0}>
          <LinksComponent
            scrollTop={scrollTop}
            pages={pages}
            location={location}
            headerstyle={headerstyle}
          />
        </Col>
        <Col lg={0} sm={6} xs={6}>
          <HamburgerComponent
            scrollTop={scrollTop}
            drawerVisibility={drawerVisibility}
            handleOnClick={() => handleOnClick()}
            headerstyle={headerstyle}
          />
        </Col>
      </Row>
      <StyledDrawer
        placement="right"
        closable={false}
        onClose={() => handleOnClick()}
        visible={drawerVisibility}
        zIndex={1}
        width={320}
      >
        <Content pages={pages} location={location} />
      </StyledDrawer>
    </StyledHeader>
  ) : null;
};

HeaderComponent.propTypes = {
  pageLinks: PropTypes.array,
  location: PropTypes.object,
  headerstyle: PropTypes.oneOf(['default', 'fullbar', 'none']),
};

HeaderComponent.defaultProps = {
  headerstyle: 'default',
};

export default HeaderComponent;
