import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { Row, Col, Space, Button } from 'antd';
import {
  FacebookOutlined,
  TwitterOutlined,
  LinkedinOutlined,
} from '@ant-design/icons';

const StyledDivWrapper = styled.div`
  background-color: ${(props) => props.theme.blackPalette.original};
  padding: 20px 0px;
  color: white;

  .ant-space-item svg {
    color: #38a1f3;
  }

  .social-buttons {
    text-align: left;
    @media only screen and (min-width: 768px) {
      text-align: right;
    }
  }
`;

function FooterComponent() {
  return (
    <StyledDivWrapper>
      <Row type="flex" justify="space-around" align="middle">
        <Col xs={21} md={15}>
          © 2013 - {moment().get('year')} PRIME Philippines All Rights Reserved
        </Col>
        <Col xs={21} md={5} className="social-buttons">
          <Space>
            <Button
              type="link"
              icon={<FacebookOutlined />}
              href="https://www.facebook.com/PRIMEPhilippines/"
            />
            <Button
              type="link"
              icon={<TwitterOutlined />}
              href="https://twitter.com/officialprimeph"
            />
            <Button
              type="link"
              icon={<LinkedinOutlined />}
              href="https://www.linkedin.com/company/prime-corporation-philippines"
            />
          </Space>
        </Col>
      </Row>
    </StyledDivWrapper>
  );
}

export default FooterComponent;
